import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
  getUser() {
    return this.http.get('user');
  }

  updateUser(user: any) {
    return this.http.post('user/update', user);
  }
}
